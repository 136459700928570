(function () {
  const hosts = {
    "127.0.0.1": "Localhost",
    "localhost": "Localhost",
    "stage.brownstonetutors.tech": "Staging",
  };

  const hostname = window.location.hostname.toLowerCase();
  const environment = hosts[hostname] ?? "Production";

  Blazor.start({ environment });
})();
